/**
 * The most important feature of this app is the way
 */

import React, {useContext, useRef} from "react";
import './App.css';
import './staged/style/staged.css';
import {Route, Routes, useNavigate} from 'react-router-dom';
import {ElementContext, GlobalStage} from "./context/Context"
import Login from './pages/Login';
import {AuthService} from './services/auth.service';

import {TestData} from "./data";


import {components as AlgoProtocol} from "./model/algotech";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {lime, orange} from "@mui/material/colors";
import {Bout} from "./components/Bout";
import TradingBout from "./pages/TradingBout";
import Main from "./pages/Main";
import {RP} from "./staged/Reference";


const authService = AuthService.getInstance();

const theme = createTheme({
    palette: {
        primary: orange,
        secondary: lime,
    },
});




function App() {

    let navigate = useNavigate();
    const algoCtx = useContext(GlobalStage);
    const contentCtx = useContext(ElementContext)
    return <ThemeProvider theme={theme}>
        <GlobalStage.Provider value={algoCtx}>

            <div>

                <div className="container mt-3">
                    {/*<BoutContext.Provider value={TestData}>*/}
                    <Routes>
                        {/*<Route path="/templates" element={<Templates/>}/>*/}
                        <Route path="/login" element={<Login/>}/>
                        <Route path="/" element={<Login/>}/>

                        <Route path="/main" element={<Main/>}/>
                        <Route path="/trader/:id" element={<TradingBout/>}/>

                    </Routes>
                    {/*</BoutContext.Provider>*/}
                </div>
            </div>
        </GlobalStage.Provider>
    </ThemeProvider>

}


export default App;
