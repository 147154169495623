import React, {FC} from "react";
import {ReferenceStage, ReferenceProps, RP, useReferenceStage} from "../../staged/Reference";
import {asCls, XMarket, XPublication, XSelection, XValue} from "../../model/typesShorthands";
import {usePublication} from "../../staged/Staged";
import {Number2String, Simple, String2String} from "../../staged/Primitive";
import {PublicationButton} from "../Publishing";
import {MarketLocation} from "../Feature";
import {useListOfReferencesStage} from "../../staged/LReference";
import {HtmlEntityProps} from "../../context/Context";
import "../style/market.css"
/**
 * Back end message
 * @param props
 * @constructor
 */
export const BEMessage = (props:{ msg?: string[] } & Partial<HtmlEntityProps>) => {
    return <div
        className={asCls("alert", props.className)}>{props.msg?.map((it) => <div key={it}>{it}</div>)}</div>
}

type MktProps = { label: string, field: string }
const tmm: MktProps[] = [
    {
        label: "Margin",
        field: "mrg"
    },
    {
        label: "Max",
        field: "max"
    },
    {
        label: "Proportional",
        field: "prp"
    },
    {
        label: "Linear",
        field: "lin"
    },
    {
        label: "Log",
        field: "log"
    },

]

export const OutMarketParameters = (props:ReferenceStage<XMarket>) => {
    let e = props.workingCopy.extValue
    let pp = usePublication(props.workingCopy.extValue.p?.pu)
    return (
        <div>
            <div className={asCls("market", pp)}>
                <h3 className={"title"}>
                    {`${e.d ?? e.n} ${e.spr ? `@${e.spr}` : ""}`}
                    {e?.f?.mrg ? <span className={"incoming-margin"}> incoming @ {e?.f?.mrg}</span> : null}
                </h3>

                {
                    tmm.map((it, idx) =>
                        <Simple key={it.field}
                                step={0.01}
                                label={it.label}
                                className={asCls(it.field, "ctl")}
                                {...props.useField<number>(it.field, 0.0)}
                                {...Number2String}
                        />
                    )
                }
                <PublicationButton
                    className={"active ctl"}
                    {...props.useField<XPublication>("p", {pu: true, pr: true})}
                    label={"Enable"}
                />
            </div>
            <BEMessage className={"ctl"} msg={[]}/>
        </div>
    )
}

export const OutMarket = (props:RP<XMarket> & { loc: MarketLocation }) => {
    const qq = useReferenceStage<XMarket>(props)

    let aa = usePublication(props.extValue.p?.pu)

    return <div id={props.loc.address(props.extValue.n)}>
        <div>
            <OutMarketParameters {...qq}/>
        </div>

        <div className={"selections"}>
            <OutSelectionList {...qq.useField("sel")}/>
        </div>
    </div>
}

export const OutSelectionList = (props:RP<XSelection[]>) => {
    let qq = useListOfReferencesStage<XSelection, string>(props, {
        identify: key => key.n,
        mark: (mod, at) => {
            mod.n = at
        }
    })


    return <>
        {"enable selection value raw bias lock min".split(" ").map(
            it => <div className={"header"} key={it}>{it}</div>)}

        {
            props.extValue === undefined ?
                null : props.extValue.sort((thi, tha) =>
                    (thi.d ?? thi.n)?.localeCompare(tha.d ?? tha.n)
                )
                    .map((om, ix) => {

                            return <OutSelection key={om.n}
                                                 className={asCls(ix % 2 === 0 ? "" : "alt",
                                                 )}
                                                 {...qq.useField(om.n, om)}/>
                        }
                    )
        }
    </>
}

export const OutSelection = (props: RP<XSelection>) => {
    const qq = useReferenceStage<XSelection>(props)

    let aa = usePublication(props.extValue.p?.pu)
    return <>
        <div className={asCls("invisible", props.className, aa)}>
            <PublicationButton {...qq.useField<XPublication>("p", {pu: true, pr: true})}
                               label={""}
            />
            <span className={"odd"}>{props.extValue.d ?? props.extValue.n}</span>
            <OutSelectionValue {...qq.useField<XValue>("v")}/>
        </div>
    </>
}

const OutSelectionValue = (props:RP<XValue>) => {
    let qq = useReferenceStage(props)
    return <>
        <span className={asCls("odd")}>
                {props.extValue.e ?? 0.0}
            </span>
        <span className={"odd raw"}>
                {props.extValue.r ?? 0.0}
            </span>
        <Simple
            step={0.01}
            label={""}
            className={""}
            {...qq.useField<number>("b", 0.0)}
            {...Number2String}
        />
        <Simple
            step={0.01}
            label={""}
            className={""}
            {...qq.useField<string>("l", "")}
            {...String2String}
        />
        <Simple
            step={0.01}
            label={""}
            className={""}
            {...qq.useField<string>("m", "")}
            {...String2String}
        />
    </>
}
