import {merging, Merging} from "./merge/Merging"

const _ = undefined
// TODO Should type it and use Partial<TYPE>
namespace MergeFun {
    function xvalue(merge: Merging) {
        merge
            .on(_, "b", "e", "r", "l", "m", "mx")
    }

    function xpub(pu: Merging){
        pu.on(_, "pu", "pr")
    }

    function threeWay(tw: Merging) {

        tw.on({expand: xvalue}, "a", "d", "h")
            .on(_, "m", "mrg")
    }


    function asianHand(ah: Merging) {
        ah.on({expand: xvalue}, "a", "h", "l")
            .on(_, "mrg")
    }

    function asianTot(at: Merging) {
        at.on({expand: xvalue}, "t", "o", "u")
            .on(_, "mrg")
    }

    function outSelection(sel:Merging, fi2?:string){
        sel
            .on({
                expand: xvalue
            }, "v")
            .on(_, "d")
            .on({expand: xpub}, "p")
    }

    function outMarket(mkt: Merging, fi?: string) {

        mkt.on(_,"d", "log", "lin", "prp", "spr", "mrg", "max")
            .on({expand: xpub}, "p")
            .onEachIn(
                {
                    expand: outSelection,
                    theirKey: (x) => x.n
                }
                ,
                "sel")
    }

    function xscoreparams(pp: Merging){
        pp.on({expand: xvalue}, "s", "drf", "e")
    }

    function xscorestat(po: Merging) {

        po.on({expand: threeWay}, "tw")
            .on({expand: asianHand}, "ah")
            .on({expand: asianTot}, "at")
            .on({expand: xscoreparams}, "b")
            .on({expand: xpub}, "p")
            .onEachIn(
                {
                    expand: outMarket,
                    theirKey: (x) => x.n
                },
                "mkt")

    }

    function feature(fe: Merging){

        fe.on({expand:xscorestat} , "x").on(_,"d")
    }



    function time(tim:Merging){
        tim.on(_, "c", "x", "a")
    }

    function progress(pro:Merging){
        pro.on(_, "n","s","d")
            .on({expand:time}, "t")
    }

    function boutinfo(bi:Merging){
        bi.on(_, "date", "v", "s", "sp", "c", "l")
            .on({expand:progress}, "prg")
    }

    function timestamp(ts:Merging){
        ts.on(_, "f")
    }

    function meta(me:Merging){
        me.on({expand:boutinfo}, "i")
            .on({expand: xpub}, "p")
            .on(_, "d")
            .on({expand:timestamp}, "ts")
    }

    export function bout(bo: Merging){
        bo.on({expand:meta},"m")
            .onEachIn(
                {
                    expand:feature,
                    theirKey: (x) => x.n
                },
                "f"
            )
    }
}

export default MergeFun;