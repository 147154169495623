import React, {useContext, useEffect, useRef, useState} from "react";
import {useParams as useRouterPath} from "react-router-dom";
import {Bout} from "../components/Bout";
import {ElementContext, GlobalStage} from "../context/Context";
import {useAlgotechWs} from "../protocol/AlgoWebSocket";

const TradingBout = () => {
    let aws = useAlgotechWs()
    let send = useRef(aws.wsh.sendMessage)
    const algoCtx = useContext(GlobalStage);
    let upar = useRouterPath()
    let bid = useRef(upar["id"]!)
    const tradingBoutRef = useRef(null)

    const [bout, setBout] = useState(aws.following.get(bid))

    useEffect(() => {
        if (bid.current !== undefined) {
            let boutId = {b: {add: [bid.current]}};
            send.current(JSON.stringify(boutId));
        }
    }, [])
    //
    useEffect(() => {

        setBout(prev => {
            let rrr = aws.following.get(bid.current)
            return rrr
        })
    }, [aws.following])


    return bout ? <ElementContext.Provider value={bout}>

        <Bout onChange={(nv) => {

            if (nv.staged) {
                aws.tweak.set(bout.m.n,
                    {
                        m: {n: bout.m.n, i: {}},
                        ...nv.staged
                    })

                algoCtx.timer.start(
                    {
                        subject: tradingBoutRef,
                        fun: () => {
                            let boutList = [...aws.tweak.values()]
                            let bid = {b: {c: boutList}}
                            aws.wsh.sendMessage(JSON.stringify(bid))
                            aws.setTweak(new Map())
                        }
                    }
                )
            }

        }
        }
              extValue={bout}
              onUnsubscribe={() => console.log("")}/>
    </ElementContext.Provider> : <div>loading...</div>
};

export default TradingBout;