import {components as AlgoProtocol} from "./algotech";


type Ty = AlgoProtocol["schemas"]

export type XBout = Ty["XBout"]
export type XInfo = Ty["XInfo"]
export type WsNotification = Ty["WsNotification"]
export type XFeature = Ty["XFeature"]
export type XPublication = Ty["XPublication"]
export type XMeta = Ty["XMeta"]
export type XScoreStat = Ty["XScoreStat"]
export type XStatisticType = Ty["XStatisticType"]
export type XScoreParams = Ty["XScoreParams"]
export type XMarket = Ty["XMarket"]
export type XTW = Ty["XTW"]
export type XAH = Ty["XAH"]
export type XAT = Ty["XAT"]
export type XMargin = Ty["XMargin"]
export type XValue = Ty["XValue"]
export type XSelection = Ty["XSelection"]

export function asCls(...mayBeUndefined:(string | undefined)[]){
    return mayBeUndefined.filter(item=> item !== undefined).join(" ")
}

namespace AlgoRoutes {
    const LOGIN:string = "/login"
    const MAIN:string = "/main"
    const TRADER:string = "/trader"
}