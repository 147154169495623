import {useContext, useState} from "react";
import { GlobalStage, StageContext} from "../context/Context";
import {HasExternal, Key, PartialOrArrayOfPartial} from "../model/workingCopy/WorkingCopy";
import {Dirtyable, DirtyControl, OnWorkingChange, StageControl, useDirty, useStageRefresh} from "./Staged";
import {ReferenceProps} from "./Reference";
import {WList} from "../model/workingCopy/WList";
import {WObject} from "../model/workingCopy/WObject";
import {WValue} from "../model/workingCopy/WValue";

interface ReferenceListStage<VALUE extends object> extends StageControl {
    stageCtx: StageContext
    dirtyCtl: DirtyControl
    workingCopy: WList<VALUE>

    useField(fieldName: string | symbol, ifMissing?: VALUE): Descendant<VALUE>

}


type Descendant<VALUE extends object> = OnWorkingChange<VALUE> & HasExternal<VALUE> & Dirtyable<VALUE>

interface IdentifierWithinList<SUBJECT, IDENTIFIED_BY> {
    identify: ((item: SUBJECT) => IDENTIFIED_BY),
    mark: (mod:Partial<SUBJECT>, at:IDENTIFIED_BY) => void
}

/**
 * Create a stage for a list of references
 *
 * @param props
 * @param fresh
 */
export function useListOfReferencesStage<VALUE extends object,
    IDENTIFIED_BY extends string | symbol | number>(props: ReferenceProps<VALUE[]>,
                                                          iwl:IdentifierWithinList<VALUE, IDENTIFIED_BY>
                                                          )
    : ReferenceListStage<VALUE> {

    // Context ----------------------------------------
    const algoCtx = useContext(GlobalStage);


    // State ----------------------------------------


    let [working, setWorking] = useState(new WList(props.extValue, iwl.identify))
    const dirty = useDirty<VALUE[]>(false, props, working)

    // Unreactive state ----------------------------------------

    // Effects --------------------------------------------


    /**
        Upon extValue change:
        Unstage all the staged
     */
    useStageRefresh<VALUE[]>(props, () => {
        if (dirty.dirty.state) {
            // TODO Pass the prev.staged

            setWorking((prev) => new WList(props.extValue, iwl.identify))
        } else {

            setWorking((prev) => new WList(props.extValue, iwl.identify))
        }

    })


    // Field Functions ------------------------------------------
    // A field here corresponds to a key in the list.
    function useField(fieldName: string | symbol, item: VALUE): Descendant<VALUE> {
        working.addItem(item)
        return {
            onChange: (newVal) => {
                algoCtx.timer.start(dirty.cleanup);
                let id_ = working.add(newVal)
                // TODO ¦¦¦ UGLY ¦¦¦
                // FIXME This two lines below are very ugly. Need to find a better way    // UGLY
                // to mark properly the staged object with the identifier within the list // UGLY
                let qq = newVal as WObject<VALUE>                               // UGLY
                // @ts-ignore                                                             // UGLY
                iwl.mark(qq.stagedProperties, new WValue(id_, id_))                       // UGLY
                // TODO ^^^ UGLY ^^^

                // Doing the following assignment does not schedule a redraw.
                // This is the correct behaviour.
                dirty.dirty.state = true
                props.onChange(working)
            },
            extValue: working.get(fieldName),
            dirty: working.isDirty() // TODO
        }
    }

    return {
        stageCtx: algoCtx,
        workingCopy: working,
        useField: useField,
        dirtyCtl: dirty
    }
}




