import {FC, ReactNode, useContext, useState} from "react";
import {GlobalStage, StageContext} from "../context/Context";
import {HasExternal} from "../model/workingCopy/WorkingCopy";
import {ComponentArgs, Dirtyable, DirtyControl, OnWorkingChange, StageControl, useDirty, useStageRefresh} from "./Staged";
import {WObject} from "../model/workingCopy/WObject";

export interface ReferenceStage<VALUE extends object> extends StageControl {
    stageCtx: StageContext
    dirtyCtl: DirtyControl
    workingCopy: WObject<VALUE>

    useField<SUB_VALUE>(fieldName: string | symbol, ifMissing?: SUB_VALUE): Descendant<SUB_VALUE>


}

export type Descendant<VALUE> = OnWorkingChange<VALUE> & HasExternal<VALUE> & Dirtyable<VALUE>


/**
 * Hook to create a widget with a staged object type.
 *
 * @param props
 * @param fresh
 */
export function useReferenceStage<VALUE extends object>(props: ReferenceProps<VALUE>): ReferenceStage<VALUE> {

    // Context ----------------------------------------
    const algoCtx = useContext(GlobalStage);


    // State ----------------------------------------
    let [working, setWorking] = useState(new WObject(props.extValue))


    const dirtyCtl = useDirty<VALUE>(false, props, working)


    // Effects --------------------------------------------


    /*
        Upon extValue change:
        Unstage all the staged

     */
    useStageRefresh<VALUE>(props, () => {
        if (dirtyCtl.dirty.state) {
            setWorking((prev) => {
                // @ts-ignore
                return new WObject(props.extValue, prev.staged)
            })
        } else {
            setWorking((prev) => {
                return new WObject(props.extValue)
            })
        }

    })


    // Field Functions ------------------------------------------
    function useField<SUBVALUE>(fieldName: string | symbol, ifMissing?: SUBVALUE): Descendant<SUBVALUE> {

        return {
            onChange: (newVal) => {

                algoCtx.timer.start(dirtyCtl.cleanup);
                // Sets the new value in the working copy.
                // If newVal is a primitive value, everything ok.

                working.set(fieldName, newVal)

                //dirty.setDirty({state: true}) FIXME Trying to see if we can prevent slowdowns
                // I replaced the above with the following that does not schedule a redraw.
                dirtyCtl.dirty.state = true
                // @ts-ignore
                props.onChange(working)
            },
            extValue: working.get(fieldName) ?? ifMissing,
            dirty: working.isDirty(fieldName)
        }
    }

    return {
        stageCtx: algoCtx,
        workingCopy: working,
        useField: useField,
        dirtyCtl: dirtyCtl
    }
}


export type ReferenceProps<VALUE> = (
    ComponentArgs<VALUE> & { children?: ReactNode | undefined }
    )
export type RP<VALUE> = ReferenceProps<VALUE>
