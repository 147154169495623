import axios from 'axios';


import {XMeta} from "../model/typesShorthands";
import {algoStorage, TOKEN_PROPERTY} from "./auth.service";
import {algoUrl, URLPatch} from "../protocol/Urls";

function getFunctor<RETURNTYPE = any>(p:URLPatch) {
    return axios.get<RETURNTYPE>(algoUrl(p),
        {headers: {
                'Authorization' : 'Bearer '+algoStorage.getItem(TOKEN_PROPERTY)
            }
        }
    )
}

export class SoccerService
{

    private static instance: SoccerService;

    private constructor() { }

    public static getInstance(): SoccerService {
        if (!SoccerService.instance) {
            SoccerService.instance = new SoccerService();
        }

        return SoccerService.instance;
    }



    public poke(what:string){
        return getFunctor({ pathname: `poke/soccer/${what}`});
    }

    public toss(){
        return getFunctor({ pathname: "toss"});
    }
    public  search(search?: string){
        return getFunctor<XMeta[]>({pathname:"bout/search", search: {q:search}});
    }

}
