import {PartialOrArrayOfPartial, WorkingCopy} from "./WorkingCopy";
import {Key} from "./WorkingCopy";

/**
 * Mapping of Partial<VALUE> into a type with same properties as VALUE, but of type WorkingCopy<VALUE[Property]>
 */
type StagedProperties<VALUE> = Partial<{
    [Property in keyof VALUE]: WorkingCopy<VALUE[Property]>
}>

/**
 * Working copy for an object.
 *
 * 'extValue' is a VALUE.
 *
 * 'staged' is a Partial<VALUE>
 *
 * 'wc' is a proxy of extValue. Its functional behaviour is described in
 * WorkingCopy's documentation.
 *
 * WObject is not as simple as WValue because to satisfy the WorkingCopy specification
 * we have to address object structures that can have arbitrary nesting.
 *
 * For this reason WObject has a field, stagedProperties, holding WorkingCopies for all
 * the fields that are modified.
 *
 * Clients use
 *    set<SUB_VALUE>(p: Key, sub: WorkingCopy<SUB_VALUE>)
 * to pass explicitly such WorkingCopy.
 *
 * It is up to the client to pass 'sub' so to respect the following
 *  **consistency invariant:**
 *
 *   if   (this.stagedProperties has property p)
 *   then this.stagedProperties.p.extValue === this.extValue.p
 *
 * See this.set and this.stagedProperties for more detailed information.
 *
 */
export class WObject<VALUE extends object> implements WorkingCopy<VALUE> {
    extValue: VALUE;
    
    get staged() {
        let _staged_: Partial<VALUE> = {}
        Reflect.ownKeys(this.stagedProperties)
            .forEach((own) =>
                Reflect.set(_staged_, own,
                    Reflect.get(this.stagedProperties, own).staged
                )
            )
        return _staged_ as PartialOrArrayOfPartial<VALUE>
    }

    wc: VALUE;
    /**
     * WorkingCopy-ies of the properties that are currently changed.
     *
     */
    stagedProperties: StagedProperties<VALUE> = {}

    /**
     * Create a new WObject
     *
     * 'wc' is implemented as a proxy over extValue.
     * If property p has a value in stagedProperties,
     *  then wc.p = stagedProperties.p.wc
     *  else wc.p = extValue.p
     *
     * @param extValue The external value
     */
    constructor(extValue: VALUE,) {
        this.extValue = extValue;
        const enclosingThis = this

        this.wc = new Proxy<VALUE>(this.extValue, {
            get(target: VALUE, p: string | symbol, receiver: any): any {

                if (Reflect.has(enclosingThis.stagedProperties, p)) {
                    return Reflect.get(enclosingThis.stagedProperties, p).wc
                } else {

                    let ext = Reflect.get(enclosingThis.extValue, p)
                    return ext
                }
            },
        })
    }

    /**
     * Stage a value for property p.
     *
     * post: this.isDirty(p) == true
     *
     * CAVEAT EMPTOR:
     * It is up to the client to make the WorkingCopy consistent with this.extValue
     * and the WorkingCopy abstraction.
     * That is, no check is made to ensure that invariants are respected.
     * See class documentation above for invariant definition.
     *
     * @param p Property that is being changed
     * @param sub A working copy of the property value.
     *
     */
    set<SUB_VALUE>(p: Key, sub: WorkingCopy<SUB_VALUE>) {
        Reflect.set(this.stagedProperties, p, sub)
    }

    /**
     * Shorthand for this.wc.p
     *
     * @param p name of property
     */
    get(p: string | symbol): any {
        let rtr = Reflect.get(this.wc, p)
        return rtr
    }

    /**
     * Unstage the current change for property p
     *
     * post: this.isDirty(p) == false
     * @param p
     */
    unset(p: string | symbol): boolean {
        return Reflect.deleteProperty(this.stagedProperties, p)
    }

    /**
     * If p is undefined:
     *   true if there are no staged properties, i.e. this.stagedProperties = {}
     *   false otherwise
     * else:
     *   true if this.stagedProperties has a property called p;
     *   false otherwise
     * @param p name of property
     */
    isDirty(p?: string | symbol): boolean {
        return (
            (p && Reflect.has(this.stagedProperties, p))
            ||
            (Object.keys(this.stagedProperties).length === 0)
        )

    }


}