import React, {
    Dispatch,
    FC,
    MutableRefObject,
    SetStateAction,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import {
    ElementContext,
    GlobalStage,
    HtmlEntityProps,
    StageContext
} from "../context/Context"
import {HasExternal, WorkingCopy} from "../model/workingCopy/WorkingCopy";
import "./style/staged.css"

/**
 * A function with a reference
 */
export interface NamedFunction {
    subject: MutableRefObject<any>;
    fun: Function;
}

// type OnDirtyFunction = (cleanup: NamedFunction) => void
type OnCleanFunction<VALUE> = (newVal: VALUE) => void

export interface Dirtyable<VALUE> {
    /**
     * Fires when the component has been cleaned. (Not sure if it is needed.)
     * This is never, nor should never be called unless the implementing object requests a timeout using cleanup
     */
    onClean?: OnCleanFunction<WorkingCopy<VALUE>>
    dirty?: boolean
}


export interface OnChange<VALUE> {
    /**
     * Action performed when an update is made (i.e., a new value is provided )
     * @param newVal
     */
    onChange: (newVal: VALUE) => void;
}

export interface OnWorkingChange<VALUE> extends OnChange<WorkingCopy<VALUE>> {

}



export type ComponentArgs<VALUE> =
    Dirtyable<VALUE> &
    OnWorkingChange<VALUE> &
    HasExternal<VALUE> &
    {hasChoices?:boolean} &
    Partial<HtmlEntityProps>



/**
 *
 */
export interface StageControl {
    stageCtx: StageContext
}


/**
 * Properties to make an item visible/invisible by means of changing its css.
 */
interface Visibility {
    visible: boolean
    setVisible: Dispatch<SetStateAction<boolean>>
    toggleVisible: () => void
    cssClass: () => string
}

/**
 * Hook telling that an object is published or unpublished
 * @param a
 */
export function usePublication(a?: boolean): string {
    return (a ?? true) ? "" : "unpub"
}

/**
 * Hook to make an object visible/invisible by means of css
 * @param initValue
 * @param classes
 */
export function useVisibility(initValue: boolean = false, classes?: {
    cl?: string, ifNot?: string
}): Visibility {
    const [visible, setVisible] = useState(initValue)
    const toggleVisible = () => setVisible((prev) => {
        return !prev
    })
    let value = () => " "
    if (classes) {

        value = () => visible ? ` ${classes.cl}` : (classes.ifNot || " ")
    }

    return {visible, setVisible, toggleVisible, cssClass: value}
}

/**
 * Enable the refresh of a stage.
 *
 * To avoid too much redrawing
 *
 * @param props
 * @param newStage
 */
export function useStageRefresh<VALUE>(props: HasExternal<VALUE>, newStage: () => void) {
    const elementCtx = useContext(ElementContext)
    const eraseStageAtNextUpdate = useRef(false)
    /**
     * Set for erasing the stage at next update
     */
    useEffect(
        () => {
            eraseStageAtNextUpdate.current = true
        }
        , [elementCtx]);

    /**
     * Create a new stage when the external value changes and the whole context changed
     */
    useEffect(() => {
        if (eraseStageAtNextUpdate.current) {
            newStage()
            eraseStageAtNextUpdate.current = false
        }
    }, [props.extValue])
}

interface DirtyState {
    state: boolean
}

export interface DirtyControl {
    dirty: DirtyState
    setDirty: Dispatch<SetStateAction<DirtyState>>
    cleanup: NamedFunction
}

export function useDirty<VALUE>(restartWhenDirty: boolean = true, d:Dirtyable<VALUE>, wc:WorkingCopy<VALUE>):DirtyControl {
    const algoCtx = useContext(GlobalStage);
    const [dirty, setDirty] = useState({state: false})

    // Unreactive state ----------------------------------------
    const ref = useRef()


    // Effects --------------------------------------------
    // Upon dirty
    useEffect(
        () => {
            if (dirty.state) {
                // OK: if thing is reopened, it is likely that one wants to edit it again
                if (restartWhenDirty) {
                    algoCtx.timer.start(cleanup)
                }
                return () => {
                    algoCtx.timer.silence(ref);
                }
            } else {

            }
        }
        , [dirty.state]);

    // Cleanup callback ----------------------------------------
    const cleanup = useMemo<NamedFunction>(() => {
        return {
            subject: ref,
            fun: () => {
                setDirty(prev => {
                    return {
                        state: false
                    }
                })
                d.onClean?.(wc)

            }
        }
    }, [d.onClean, wc])

    return {dirty, setDirty, cleanup}
}




