import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {WebSocketHook} from "react-use-websocket/dist/lib/types";
import {useNavigate} from "react-router-dom";
import {AuthService} from "../services/auth.service";
import {TestData} from "../data";

import {saveSubscription, restoreSubscriptions} from "./Subscriptions"
import useWebSocket from "react-use-websocket";
import MergeFun from "../model/algotechMerge";
import {merging} from "../model/merge/Merging";
import {SoccerService} from "../services/soccer.service";

import {
    XBout,
    WsNotification
} from "../model/typesShorthands";
import {wsUrl} from "./Urls";

export interface AlgotechWs {
    // Tells which bouts are being followed
    following: Map<any, XBout>
    // Hook for adding bouts
    setFollowing: Dispatch<SetStateAction<Map<any, XBout>>>
    // Tells the item currently being tweaked
    tweak: Map<any, Partial<XBout>>
    // Hook for setting a new tweak
    setTweak: Dispatch<SetStateAction<Map<any, XBout>>>
    // Websocket hook
    wsh: WebSocketHook
}

export function useLogin(uponFailure:string, pending:string = window.location.pathname){
    let navigate = useNavigate();
    // Needed for using the token.
    const authService = AuthService.getInstance();
    useEffect(() => {
            if(!authService.isLogged())
                // sessionStorage.setItem("pending", pending)
                navigate(`${uponFailure}?from=${pending}`)
        }
        ,[])
}



export function useAlgotechWs(): AlgotechWs {
    useLogin("/login", window.location.pathname)
    const authService = AuthService.getInstance();
    let initial = new Map<any, XBout>()
    const [following, setFollowing] = useState<Map<any, XBout>>(initial)
    const [tweak, setTweak] = useState<Map<any, XBout>>(initial)


    const [wsUrlState, setWsUrlState] = useState(wsUrl(authService.getToken() ?? undefined))


    const wsh = useWebSocket(wsUrlState, {
        onOpen: () => console.log('opened'),
        //Will attempt to reconnect on all close events, such as server shutting down
        shouldReconnect: (closeEvent) => true,
    });

    // When a message comes from the websocket
    useEffect(() => {

        if (wsh.lastJsonMessage !== null) {

            let qq = (wsh.lastJsonMessage as WsNotification)
            setFollowing((prev) => {

                    let another = new Map<number, XBout>(prev)

                    qq.b?.c?.forEach(
                        bout => {
                            if(another.has(bout.m.n)){

                                let prev = another.get(bout.m.n)!
                                MergeFun.bout(merging(prev, bout))
                                another.set(bout.m.n, {...prev, m:{...prev.m}})
                            }else {
                                another.set(bout.m.n, bout)
                            }
                            // saveSubscription(bout.m.n)
                        }
                    )


                    return another
                }
            )



        }
    }, [wsh.lastMessage]);

    useEffect(() => {
        let subs = restoreSubscriptions()
        if (subs){
            let boutId = {b: {add: subs}};
            wsh.sendMessage(JSON.stringify(boutId));
        }else {
            console.log("Found no subscriptions")
        }
    }, [])

    return {following, setFollowing, tweak: tweak, setTweak: setTweak, wsh}
}

export function useTrader(): AlgotechWs {
    // needed for poke, search
    const soccerService = SoccerService.getInstance();
    return useAlgotechWs()
}


