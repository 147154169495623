import React, {FC} from "react";

import {ReferenceProps, useReferenceStage} from "../staged/Reference";
import {Simple, Boolean2String} from "../staged/Primitive";
import {XPublication} from "../model/typesShorthands"

export const PublicationButton: FC<ReferenceProps<XPublication> & {label?:string}> = (props) => {
    const qq = useReferenceStage<XPublication>(props)

    let lname = props.label??""
    return (
        <div className={props.className}>
            <Simple
                label={lname}
                {...qq.useField<boolean>("pu")} {...Boolean2String}/>


        </div>)

}