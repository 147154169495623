import React, {FC} from "react";
import {
    usePublication,
    useVisibility
} from "../staged/Staged";
import {ReferenceProps, RP, useReferenceStage} from "../staged/Reference";
import {
    XScoreParams,
    XMarket,
    XScoreStat,
    XAT,
    XAH,
    XTW,
    XValue,
    asCls,
    XStatisticType, XPublication
} from "../model/typesShorthands";


import {useListOfReferencesStage} from "../staged/LReference";
import "./style/feature.css"
import {WValue} from "../model/workingCopy/WValue";
import {PublicationButton} from "./Publishing";
import {AsianHandicap, AsianTotal, ScoreParams, ThreeWay} from "./market/Incoming";
import {OutMarket} from "./market/Outgoing";

const OutMarketContext = React.createContext<boolean>(false)

export class MarketLocation {
    feature: string
    bout: number

    constructor(bout: number, feat: string) {
        this.bout = bout
        this.feature = feat
    }

    address(market?: string): string {
        return [this.bout, this.feature, market].join(":")
    }
}


export const ScoreFeat = (props:RP<XScoreStat> & { name: string, loc: MarketLocation }) => {
    const qq = useReferenceStage<XScoreStat>(props)
    if (!props.extValue.mkt) {
        props.extValue.mkt = []
    }
    /*const ulf = qq.useListOfReferencesField<XMarket>("mkt", item => item.n)*/
    const {visible, setVisible, toggleVisible, cssClass} = useVisibility(false, {cl: "pushed", ifNot: "unpushed"})
    let pp = usePublication(props.extValue.p?.pu)
    let preview = null
    let ttype: XStatisticType = (props.extValue['#t'] as XStatisticType)
    qq.workingCopy.set<XStatisticType>("#t", new WValue(ttype, ttype))
    if (ttype === 'Sc') {
        preview = <>

            <div className={"title"}>
                    {props.name}
            </div>
            <div className={asCls("incoming fctl follow", pp)}>
                <div className={""}>
                    <PublicationButton
                        {...qq.useField<XPublication>(
                            "p",
                            {pu: true, pr: true})}
                        label={"Enable"}
                    />
                    <div className={"tw"}><ThreeWay {...qq.useField<XTW>("tw", {})}/></div>
                    <div className={"ah"}><AsianHandicap {...qq.useField<XAH>("ah", {})}/></div>
                    <div className={"at"}><AsianTotal {...qq.useField<XAT>("at", {})}/></div>
                    <div className={"scrollable"}>
                        <h3>Markets</h3>
                        <ul>
                            {props.extValue.mkt
                                .filter(filterOutNegativeAsianTotal)
                                .map(
                                item => <li key={item.n}>
                                    <a className={item.p?.pu ? "": "unpub" } href={`#${props.loc.address(item.n)}`}
                                       onClick={evt => {
                                           let _goto = document.getElementById(props.loc.address(item.n))

                                           _goto?.scrollIntoView({
                                               behavior: "smooth",
                                               block: "center",
                                               inline: "nearest"
                                           })


                                           evt.preventDefault()
                                       }}
                                    >{`${item.d ?? item.n} ${item.spr ? `@${item.spr}` : ""}`}</a>
                                </li>)}
                        </ul>
                    </div>
                </div>
            </div>

            <ScoreParams className={asCls("param follow fctl", pp)} {...qq.useField<XScoreParams>("b", {})}/>
        </>

    } else {
        preview = <>
            <div className={"title"}>{props.name}</div>
            {props.extValue.mkt.length > 1 ? <div className={"incoming fctl follow"}>
                <div className={""}>
                    <div className={"scrollable"}>
                        <h3>Markets</h3>
                        <ul>
                            {props.extValue.mkt
                                .filter(filterOutNegativeAsianTotal)
                                .map(
                                item => <li key={item.n}>
                                    <a
                                        className={item.p?.pu ? "": "unpub" }
                                        href={`#${props.loc.address(item.n)}`}
                                       onClick={evt => {
                                           let _goto = document.getElementById(props.loc.address(item.n))

                                           _goto?.scrollIntoView({
                                               behavior: "smooth",
                                               block: "center",
                                               inline: "nearest"
                                           })
                                           evt.preventDefault()
                                       }}
                                    >{`${item.d ?? item.n} ${item.spr ? `@${item.spr}` : ""}`}</a>
                                </li>)}
                        </ul>
                    </div>
                </div>
            </div> : null}

        </>

    }


    return <div className={"feature"}>

        {preview}
        <OutMarketContext.Provider value={true}>
            <div className={"market-list"}>
                <MarketList  {...qq.useField("mkt")} loc={props.loc}/>
            </div>
        </OutMarketContext.Provider>
    </div>
}

function filterOutNegativeAsianTotal(it:XMarket) : Boolean{
    return !(it.spr !== undefined && it.spr < 0 && it.n.startsWith("rat"))
}


export const MarketList = (props:RP<XMarket[]> & { loc: MarketLocation }) => {
    let qq = useListOfReferencesStage<XMarket, string>(props,
        {
            identify: item => item.n,
            mark: (mod, at) => {
                mod.n = at
            }
        }
    )

    return <div className={"mkt"}>

        {props.extValue
            ?.sort((thi, tha) => {
                    if (thi.n.includes("_")) {
                        if (tha.n.includes("_")) {
                            return thi.n.localeCompare(tha.n)
                        } else {
                            return 1
                        }
                    } else {
                        if (tha.n.includes("_")) {
                            return -1
                        } else {
                            return thi.n.localeCompare(tha.n)
                        }
                    }
            })
            ?.filter(filterOutNegativeAsianTotal)
            ?.map((om) => (<div key={om.n}>

                <OutMarket loc={props.loc}
                           {...qq.useField(om.n, om)}/>
                <hr/>
            </div>))}

    </div>
}

