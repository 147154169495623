export const SUBSCR = "subscriptions"

export const oldSubscriptions = new Set<number>()

export function saveSubscription(i:number){
    oldSubscriptions.add(i)
    localStorage.setItem(SUBSCR, JSON.stringify([...oldSubscriptions]))
}

export function forgetSubscription(i:number){
    oldSubscriptions.delete(i);
    localStorage.setItem(SUBSCR, JSON.stringify([...oldSubscriptions]))
}

export function restoreSubscriptions(){

    let subs:Array<number> = JSON.parse(localStorage.getItem(SUBSCR) ?? "[]") as Array<number>

    subs.forEach((it:number) => oldSubscriptions.add(it))
    if (subs){
        return [... subs]
    }else return null
}


