export interface URLPatch {
    protocol?: string
    pathname?: string
    search?: any
    hash?: string
}

export function algoUrl(arg?: URLPatch): string {
    let origin = window.location.origin
    // TODO if internet explorer this won't work
    let u = new URL(origin)
    if (arg) {
        if (arg.protocol) u.protocol = arg.protocol
        if (arg.pathname) u.pathname = arg.pathname
        if (arg.search) {
            for (const prop in arg.search) {
                u.searchParams.set(prop, arg.search[prop])
            }
        }
        if (arg.hash) u.hash = arg.hash
    }

    let surl = u.toString()
    URL.revokeObjectURL(surl)
    return surl
}

export function wsUrl(authToken?: string) {
    let loc = window.location;
    let wsProto = "ws";
    if (loc.protocol === "https:") {
        wsProto = wsProto + "s";
    }

    return algoUrl({
        protocol: wsProto,
        pathname: "tws",
        search: {
            token: authToken
        }
    })
}
