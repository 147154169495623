import axios from 'axios';
import {algoUrl} from "../protocol/Urls";


let production = false

export const TOKEN_PROPERTY:string = "algotech-token"
export var algoStorage = production?sessionStorage:localStorage

export class AuthService {

    private static instance: AuthService;

    private constructor() {
    }

    public static getInstance(): AuthService {
        if (!AuthService.instance) {
            AuthService.instance = new AuthService();
        }

        return AuthService.instance;
    }

    public isLogged(): boolean {

        let il = this.getToken() ? true : false;
        return il
    }

    public getToken() {
        return algoStorage.getItem(TOKEN_PROPERTY);
    }

    public doLogin(username: string, password: string, resolve: any, error: any) {
        axios.get(algoUrl({pathname: "jwt/apilogin"}), {
            auth: {
                username: username,
                password: password
            }
        }).then(data => {
            algoStorage.setItem(TOKEN_PROPERTY, data.data)
            resolve()
        }).catch(error())
    }

}
