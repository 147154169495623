import React, {FC} from "react";
import {usePublication} from "../staged/Staged";
import {HasExternal} from "../model/workingCopy/WorkingCopy";
import {Descendant, ReferenceProps, RP, useReferenceStage} from "../staged/Reference";
import {MarketLocation, ScoreFeat} from "./Feature";
import {XBout, XFeature, XMargin, XMeta, XScoreStat} from "../model/typesShorthands";
import {useListOfReferencesStage} from "../staged/LReference";
import "./style/bout.css"

export const BoutContext = React.createContext<XBout | null>(null)

export const FeatSummary = (props:RP<XFeature[]> & { boutId: number }) => {

    return <div className={props.className}>
        <h3>Features</h3>
        {props.extValue
            ?.sort((thi, tha) => {
                return thi.n.localeCompare(tha.n)
            })
            ?.map(item => <FeatSummaryItem
                key={item.n}
                extValue={item}
                loc={new MarketLocation(props.boutId, item.n)}/>)}
    </div>
}

export const Bout = (props:(RP<XBout> & { onUnsubscribe: (identifier: number) => void })) => {
    let qq = useReferenceStage(props)

    let vis = usePublication(props.extValue.p?.pu)
    let bid = props.extValue.n ?? 0
    let ff:Descendant<XFeature[]> = qq.useField('f')
    return <div className={vis}>
        <BoutContext.Provider value={props.extValue}>
            <div className={"bout"}>
                <div className={"title"}>
                    <BoutInfo
                        onUnsubscribe={props.onUnsubscribe}
                        extValue={props.extValue.m}/>

                </div>



                <FeatureList className={"feature-exp-list"}
                             boutId={bid}
                             {...ff}
                             />


            </div>

        </BoutContext.Provider>
    </div>
}

export const BoutInfo = (props:HasExternal<XMeta> & { onUnsubscribe: (identifier: number) => void }) => {
    // let tmpCtx = useContext(TemplateContext)
    let x = props.extValue
    return <div>
        <div>{x.n} {x.d} {(x.i) ? `${x.i.date}, currently ${x.i.prg?.d ?? x.i.prg?.n} , ${x.i.prg?.s?.join(" - ")}, ${x.i.prg?.t.c}` : "(information missing)"}'</div>


    </div>

}

export const FeatureList = (props:ReferenceProps<XFeature[]> & { boutId: number }) => {

    let fea = useListOfReferencesStage<XFeature, string>(props, {
        identify: (item) => item.n,
        mark: (mod, at) => {
            mod.n = at
        }
    })

    let references = <div className={"feat-list scrollable"}>
        <h3>Features</h3>

            {props.extValue
                ?.sort((thi, tha) => {
                    return thi.n.localeCompare(tha.n)
                })
                ?.map(item =>
                    <FeatSummaryItem
                        key={item.n}
                        extValue={item}
                        loc={new MarketLocation(props.boutId, item.n)}/>)}

    </div>

    return (
        <>
        {references}
        <div className={props.className}>
        {
            props.extValue
                .map((fe) => <BoutFeat
                    loc={new MarketLocation(props.boutId, fe.n)}
                    key={fe.n}
                    {...fea.useField(fe.n, fe)}/>)}

    </div>
        </>)
}

export const FeatSummaryItem = (props:{ extValue: XFeature, loc: MarketLocation }) => {

    let qq = usePublication(props.extValue.p?.pu)

    return <div key={props.extValue.n}><a

        href={`#${props.loc.address()}`}
        onClick={
            evt => {
                let _goto = document.getElementById(props.loc.address())
                _goto?.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "nearest"
                })
                evt.preventDefault()
            }
        }

    >{props.extValue.n} {props.extValue.k} </a></div>

}

export const BoutFeat = (props:ReferenceProps<XFeature> & { loc: MarketLocation }) => {
    let qq = useReferenceStage<XFeature>(props)

    let content = null
    if ((props.extValue?.x?.["#t"] === "Sc") ?? false) {
        let sta = props.extValue.x as XScoreStat

        content = <ScoreFeat

            name={props.extValue.d ?? props.loc.feature}
            loc={props.loc}
            {...qq.useField<XScoreStat>("x")}

        />
    } else if ((props.extValue?.x?.["#t"] === "Ma") ?? false) {
        let sta = props.extValue.x as XMargin
        content = <ScoreFeat

            name={props.extValue.d ?? props.loc.feature}
            loc={props.loc}
            {...qq.useField<XMargin>("x")}

        />
    }

    return <>

        <div className={""} id={props.loc.address()}>

            {content}
        </div>

    </>
}