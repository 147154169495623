import {GlobalStage, StageContext} from "../context/Context";
import {ComponentArgs, DirtyControl, StageControl, useDirty, useStageRefresh} from "./Staged";
import React, {ReactNode, useContext, useState} from "react";
import {WValue} from "../model/workingCopy/WValue";

export type PrimitiveProps<VALUE> =
    ComponentArgs<VALUE>
    & PrimitiveConverter<VALUE>
    & { children?: ReactNode | undefined }


interface PrimitiveStage<VALUE extends boolean | string | number> extends StageControl {
    stageCtx: StageContext
    dirtyCtl: DirtyControl
    workingCopy: WValue<VALUE>
}

export interface PrimitiveConverter<VALUE> {
    // TODO Maybe from needs a validator
    from: (incoming: any) => VALUE;
    to: (outgoing: VALUE) => string;
}


/**
 * Hook for creating a
 * @param props
 */
export function usePrimitiveStage<VALUE extends boolean | string | number>(props: PrimitiveProps<VALUE>):
    PrimitiveStage<VALUE> {
    // Context ----------------------------------------
    const algoCtx = useContext(GlobalStage);

    // State ----------------------------------------
    const [working, setWorking] = useState(new WValue((props.extValue)))
    const dirty = useDirty<VALUE>(true, props, working)

    useStageRefresh<VALUE>(props, () => {
        if (dirty.dirty.state) {
            setWorking(prev => {
                return new WValue(props.extValue, prev.staged)
            })
        } else {
            setWorking(prev => {
                return new WValue(props.extValue)
            })
        }
    })

    return {
        stageCtx: algoCtx,
        dirtyCtl: dirty,
        workingCopy: working
    }
}


export function Simple<VALUE extends boolean | string | number>(props: PrimitiveProps<VALUE>
    & { choices?: boolean, pattern?: string }) {
    const swc = usePrimitiveStage(props)

    let ty = ""
    let thisTy = typeof props.extValue;
    ty = stagedPrimitiveTypes[thisTy] ?? thisTy;

    let changeInInputContent = (a: any) => {
        let q = props.from(a)
        // @ts-ignore
        swc.workingCopy.set(q)
        swc.dirtyCtl.setDirty({state: true})
        props.onChange(swc.workingCopy/*q as EnhancedPartial<typeof q>*/)
    }
    // console.log("Redrawing")
    // console.log(props.extValue)
    let appearance = swc.dirtyCtl.dirty.state ? "dirty" : "clean"
    return <label className={[appearance, props.className ?? ""].join(" ") }>
        <span>{props.label ?? "lab!"}</span>
        <input className={props.className + " " + appearance}
               type={ty}
               defaultChecked={Boolean(swc.workingCopy.wc)}
               value={props.to(swc.workingCopy.wc)}
               step={props.step}
               onChange={(evt) => {
                   if (ty === "checkbox") {
                       changeInInputContent(!swc.workingCopy.wc)
                   } else changeInInputContent(evt.target.value)
               }}
               pattern={props.pattern}
            // TODO Pass the tfprops
        />

    </label>

}


const stagedPrimitiveTypes: any = {
    boolean: 'checkbox',
    string: 'text',
}

export const String2String: PrimitiveConverter<string> = {
    from: (s: string) => s,
    to: (s: string) => s
}

export const Number2String: PrimitiveConverter<number> = {
    from: (s: string) => Number(s),
    to: (s: number) => s.toString()
}

export const Boolean2String: PrimitiveConverter<boolean> = {
    from: (s: string) => {

        return Boolean(s)
    },
    to: (s: boolean) => {

        return s.toString()
    }
}


