import React, {FC} from "react";
import {ReferenceProps, RP, useReferenceStage} from "../../staged/Reference";
import {asCls, XAH, XAT, XScoreParams, XTW, XValue} from "../../model/typesShorthands";
import {Number2String, Simple, String2String} from "../../staged/Primitive";
import "../style/market.css"

const InSelection: FC<ReferenceProps<XValue> & { spec: ValueSpec, className: string }> = props => {

    let qq = useReferenceStage(props)

    return <>
        <div className={asCls("invisible", props.className)}>

            <span className={"odd"}>{props.spec.name}</span>
            <span className={"odd"}>{props.extValue.e ?? NaN.toString()}</span>
            <span className={"odd raw"}>{props.extValue.r ?? NaN.toString()}</span>
            {props.spec.subfields.map((sf: SubFieldSpec) =>
                sf.field === "b" ? <Simple key={sf.field}
                                           step={sf.step}
                                           label={""}
                                           className={""}
                                           {...qq.useField<number>(sf.field, 0.0)}
                                           {...Number2String}
                /> : <Simple key={sf.field}
                             step={sf.step}
                             label={""}
                             className={""}
                             {...qq.useField<string>(sf.field, "")}
                             {...String2String}
                />
            )}


        </div>
    </>
}

interface FieldSpec {
    field: string;
    name: string;
}

interface ValueSpec extends FieldSpec {
    subfields: SubFieldSpec[]
}

interface SubFieldSpec extends FieldSpec {
    step: number
}

export const InMarket = (props:RP<object> & {
    name: string,
    targetLabel: string,
    inLabels: ValueSpec[]
}) => {
    const qq = useReferenceStage(props)

    return <div>
        <span>{props.name}</span>
        <div className={"in-market"}>
            <div className={"in-selections"}>
                {
                    `${props.targetLabel} value raw bias`.split(" ").map(it =>
                        <div className={"header"} key={it}>{it}
                        </div>)
                }
                {
                    props.inLabels.map((item, ix) =>

                        <InSelection className={ix % 2 === 0 ? "" : "alt"} key={item.name}
                                     spec={item}{...qq.useField(item.field, {})}/>)
                }
            </div>
        </div>
    </div>
}

const biasAndLock = [
    {
        name: "bias",
        field: "b",
        step: 0.01
    },
    {
        name: "lock",
        field: "l",
        step: 0.01
    },
]
const minAndMax = [
    {
        name: "min",
        field: "m",
        step: 0.01
    },
    {
        name: "max",
        field: "mx",
        step: 0.01
    },
]


const labs = [
    {field: "e", name: "Expectancy", subfields: biasAndLock},
    {field: "s", name: "Supremacy", subfields: biasAndLock},
    {
        field: "drf", name: "DRF",
        subfields: [...biasAndLock, ...minAndMax]
    }
]

export const ScoreParams = (props:ReferenceProps<XScoreParams>) => {

    const qq = useReferenceStage(props)
    return <div className={props.className}>
        <span>{"params"}</span>
        <div className={"in-market"}>
            <div className={"in-score-params"}>
                {/* Header */}
                {" value raw bias lock min max".split(" ").map(it => <div className={"header"} key={it}>{it}</div>)}

                {/* Content */}
                {labs.map((item, ix) => {
                        let alt = ix % 2 === 0 ? "" : "alt"
                        return <div className={asCls("invisible", alt)}
                                    key={item.name}>

                            <InSelection className={alt} key={item.name}
                                         spec={item}
                                         {...qq.useField(item.field, {})}/>
                            {item.field !== "drf" ? <>
                                <div key={"fill1"}></div>
                                <div key={"fill2"}></div>
                            </> : null}
                        </div>
                    }
                )
                }


            </div>
        </div>
    </div>
}


export const AsianHandicap = (props:RP<XAH>) => {
    return <InMarket {...props} name={"Incoming Asian Handicap"} targetLabel={""} inLabels={[
        {
            field: "l", name: "handicap", subfields: [{
                name: "bias",
                field: "b",
                step: 0.25
            }]
        },
        {
            field: "h", name: "home",
            subfields: [{
                name: "bias",
                field: "b",
                step: 0.01
            }]
        },
        {
            field: "a", name: "away",
            subfields: [{
                name: "bias",
                field: "b",
                step: 0.01
            }]
        }
    ]}/>
}

export const ThreeWay = (props:RP<XTW>) => {
    return <InMarket {...props} name={"Incoming Three way"}
                     targetLabel={""}
                     inLabels={[
                         {
                             field: "h", name: "home",
                             subfields: [{
                                 name: "bias",
                                 field: "b",
                                 step: 0.01
                             }]
                         },
                         {
                             field: "d", name: "draw", subfields: [{
                                 name: "bias",
                                 field: "b",
                                 step: 0.01
                             }]
                         },
                         {
                             field: "a", name: "away",
                             subfields: [{
                                 name: "bias",
                                 field: "b",
                                 step: 0.01
                             }]
                         }]}/>
}


export const AsianTotal = (props:RP<XAT>) => {
    return <InMarket {...props} name={"Incoming Asian Total"}
                     targetLabel={""}
                     inLabels={[
                         {
                             field: "t", name: "total", subfields: [{
                                 name: "bias",
                                 field: "b",
                                 step: 0.25
                             }]
                         },
                         {
                             field: "o", name: "over", subfields: [{
                                 name: "bias",
                                 field: "b",
                                 step: 0.01
                             }]
                         },
                         {
                             field: "u", name: "under", subfields: [{
                                 name: "bias",
                                 field: "b",
                                 step: 0.01
                             }]
                         }
                     ]}/>
}