type Expanding = (mrg: Merging, field?: string) => void;

type Creating = () => any;

/**
 * Specifies the merge actions to perform when visiting the object tree.
 */
interface Continuing {
    /**
     * Continue merging one level down on a property that is of type object.
     * Argument `mrg` is the Merging object for the expanded field.
     */
    expand: Expanding;
    /**
     * If the property name is undefined, this function is executed and its return value assigned to this.ours[name].
     */
    create?: Creating;
}

/**
 *
 * @param {String} name - A property name
 * @param {(Merging)->()}
 * @param {() -> Any} creating -
 */


/**
 * @param {(String)-> Boolean} filter - onEach

 * @param {(String, Merging)->()} expand - Continue merging one level down
 *      on a property.
 *      Input arguments are : property name, new Merging(this.ours[name], this.theirs[name]).
 * @param {(object)->(String)} theirKey -
 */


/**
 * Specifies the merge actions to perform when visiting the object tree.
 */
interface ContinuingEach {
    /**
     * Continue merging one level down on a property that is of type object.
     */
    expand: Expanding,
    /**
     * If you are merging when
     *      this.ours    | is an object
     *      this.theirs  | is a list of objects
     * specify theirKey to extract a key from each item in this.theirs,
     * making the merge equivalent to merging two objects
     */
    theirKey: TheirKey,
    /** Merge properties p for which filter(p) = truthy
     does nothing on properties p for which filter(p) = falsy
     */
    filter?: Filter
}

type Projection = (field: string) => Merging;
type Filter = (field: string) => true;
type TheirKey = (mrg: any) => string;

/**
 * A class for merging the values of properties of same name in two distinct objects,
 * this.ours and this.theirs.
 * ***Merging's methods have side effects on this.ours.***
 */
export class Merging {
    ours: any;
    theirs: any;

    constructor(ours: any, theirs: any) {
        this.ours = ours
        this.theirs = theirs
    }

    exists(x: any): boolean {
        return x !== undefined
    }

    /**
     Shorthand for  on1(a).on1(b)...on1(z)
     */
    on(continuing?: Continuing,
       ...fields: string[]) {
        return this.applyAll((v: string) => this.on1(v, continuing), fields)
    }


    /**
     Shorthand for  nullOn1(a).nullOn1(b)...nullOn1(z)
     */
    nullOn(continuing?: Continuing, ...fields: string[]) {
        return this.applyAll((v: string) =>
            this.nullOn1(v,
            ), fields)
    }

    /**
     (PRIVATE) Applies func to all fields.
     */
    private applyAll(func: Projection, fields: string[]) {
        fields.forEach(func)
        return this
    }

    /**
     * Defines a monotonic merging on property name.
     *
     * Monotonic means that whenever this.theirs has a undefined/an explicitly falsy value at property name,
     * the merging is ignored.
     *
     * @param name A property name
     * @param continuing
     * @return this, for chaining
     */
    on1(name: string,
        continuing?: Continuing): Merging {
        var q = (name in this.theirs) ? this.theirs[name] : undefined
        if (this.exists(q)) {

            if (continuing) {

                var newOurs = this.ours[name] || (continuing.create ? continuing.create() : {})
                this.ours[name] = newOurs
                continuing.expand(new Merging(this.ours[name], this.theirs[name]))
            } else {
                this.ours[name] = q
            }
        }
        return this
    }

    /**
     * Defines a non-monotonic merging on property name.
     *
     * Non-monotonic means that whenever this.theirs has a n undefined/an explicitly falsy value at property name,
     * the property is deleted from this.ours.
     *
     * @param name A property name
     * @param continuing
     * @return this, for chaining
     */
    nullOn1(name: string,
            continuing?: Continuing,
    ):Merging {
        var q = this.theirs[name]
        if (this.exists(q)) {
            this.on1(name,
                continuing
            )
        } else {
            delete this.ours[name]
        }
        return this
    }

    /**
     * Applies a merge on all the properties of this.theirs.
     *
     * Useful when you do not know which names will this.theirs'properties have.
     *
     * @return this, for chaining
     * @param cEach
     */
    onEach(
        cEach: ContinuingEach
    ):Merging {


        let ours = this.ours.reduce((acc:any,nx:any) => {acc[cEach.theirKey(nx)] = nx; return acc}, {})
        var assign = (ass: string, val: any) => this.ours[ass] = val

        for (var x in this.theirs) { // if it is a list, x is an element; if not, an object
            // if (cEach.filter && cEach.filter(x)) {


                var value = this.theirs[x]
                var name = this.exists(cEach.theirKey) ? cEach.theirKey(value) : x
                var ou = ours[name]
            // TODO
                if (this.exists(ou) && cEach.expand) {
                    try {
                        cEach.expand(new Merging(ou, value), name)
                    } catch {
                        // likely, a nested merge ended with an exception
                        // because we were merging on a simple type
                        assign(name, value);
                    }
                } else {
                    assign(name, value);
                }
            // }
        }
        return this;
    }


    /**
     * Applies a merge on all the properties of this.theirs[name].
     *
     * Useful when you have a fields of collections of same type.
     *
     * @param cEach
     * @param fields
     * @return this, for chaining
     */
    onEachIn(
        cEach: ContinuingEach
        , ...fields: string[]) {
        this.on(
            {
                expand: (collection) => {
                    collection.onEach(cEach)
                }
            }
            ,
            ...fields)

    }

}

const merging = (ours: any, theirs: any) => new Merging(ours, theirs)

export {
    merging
};