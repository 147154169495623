import React from "react";
import {CommitTimer} from "../staged/Commit";


export interface HtmlEntityProps {
    onMouseEnter: (evt: any) => void
    onMouseLeave: (evt: any) => void
    onClick: (evt: any) => void
    className: string
    disabled: boolean
    step: number
    label: string
}


export class StageContext {
    timer: CommitTimer = new CommitTimer()
    current: any

    constructor(current: any) {
        this.current = current;
    }
}


export class TraderContext {

    constructor() {

    }
}


export const GlobalStage = React.createContext(
    new StageContext(
        {}
    )
);

export const ElementContext = React.createContext({})

