
export function* mapIter<X, Y>(iterable: Iterable<X>, callback: (x: X) => Y) {
    for (let x of iterable) {
        yield callback(x)
    }
}

export function* filtIter<X>(iterable: Iterable<X>, callback: (x: X) => boolean) {
    for (let x of iterable) {
        if (callback(x)) {
            yield x
        }
    }
}