import React, {FC, useRef, useState} from "react";

import "./style/landing.css"
import {SoccerService} from "../services/soccer.service";
import {XMeta} from "../model/typesShorthands";
import {useLogin} from "../protocol/AlgoWebSocket";
import {OnChange} from "../staged/Staged";

const Main = () => {
    useLogin("/login")
    // const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined)
    const [matches, setMatches] = useState<XMeta[]>([]);
    const [, setFiltered] = useState<XMeta[]>([]);

    return <div className={"search-form"}>
        <Search
            onChange={nv => {
                soccerService.search(nv)
                    .then(response => {
                        setMatches(prv => [...response.data])
                        setFiltered(prv => [...response.data])
                    })
            }
            }
        />
        <Results
            matches={matches} // TODO filters depending on change
        />
    </div>
};

const soccerService = SoccerService.getInstance();

const Search: FC<OnChange<string>> = props => {

    const [query, setQuery] = useState<string>("");

    return <label htmlFor={"main-search"}>
        Search
        <input id={"main-search"}
                                    type={"text"}
                  value={query}

                  onChange={evt => {
                      let val = evt.target.value
                      setQuery((prev) => {
                          props.onChange(val)
                          return val
                      })

                  }
                  }
    /></label>
}

const Results: FC<{ matches: XMeta[] }> = (props) => {
    let columns = useRef("open sport country league participants date status".split(" "))

    const subt = (item: { [key: string]: string }) => {
        let keys = Reflect.ownKeys(item)
        return <table>
            <thead>
            <tr>{keys.map(key =>
                <th key={key.toString()}>
                    {key}
                </th>)}
            </tr>
            </thead>
            <tbody>
            <tr>
                {keys.map(key =>
                    <td key={key.toString()}>
                        {item[key.toString()]}
                    </td>)}
            </tr>
            </tbody>
        </table>
    }

    return <table>
        <thead>
        <tr>{columns.current.map(item => <th key={item}>{item}</th>)}</tr>
        </thead>
        <tbody>
        {props.matches.map(item =>
            <tr key={item.n}>
                <td><a href={`/trader/${item.n}`} target={"_blank"} rel={"noreferrer"}>{item.d}</a></td>
                <td>{item.i.sp}</td>
                <td>{item.i.c}</td>
                <td>{item.i.l}</td>
                <td>
                    {item.i.p ? subt(item.i.p) : null}
                </td>
                <td>{item.i.date}</td>
                <td>{item.i.prg?.n} ({item.i.s})</td>

            </tr>
        )}

        </tbody>
    </table>
}

export default Main;