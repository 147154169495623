import {PartialOrArrayOfPartial, WorkingCopy} from "./WorkingCopy";

type Primitive = string | number | boolean

/**
 * Implementation of a WorkingCopy for a primitive value.
 *
 * 'extValue' is either boolean | string | number | symbol.
 * 'staged' is either null, or a value of type boolean | string | number | symbol.
 * 'wc' is 'staged'?'extValue':'staged'
 *
 * That is, this object represents a value or its overwritten value if present.
 */
export class WValue<VALUE extends boolean | string | number | symbol>
    implements WorkingCopy<VALUE>{
    staged?: PartialOrArrayOfPartial<VALUE>
    extValue: VALUE

    constructor(extValue: VALUE, staged?: PartialOrArrayOfPartial<VALUE>) {
        this.staged = staged
        this.extValue = extValue
    }

    get wc(): VALUE {
        // Safe to ignore here!
        // @ts-ignore
        return this.staged === undefined ? this.extValue : this.staged;
    }

    set(v: PartialOrArrayOfPartial<VALUE>): boolean {
        this.staged = v
        return true
    }

    clear(v : VALUE, clearStaged:boolean = false) {
        if (clearStaged) {
            this.staged = undefined
        }
        this.extValue = v
    }

    isDirty(): boolean {
        return this.staged !== undefined
    }
}
