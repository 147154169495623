import {NamedFunction} from "./Staged";

/**
 * Key holding the default max value
 */
const MAX_TIMER = "maxTimer";

/**
 * A timer which, when elapsed, executes the cleanup callbacks of dirty components.
 */
export class CommitTimer {
    /** Time limit before running cleanups */
    maximum: number = Number(localStorage.getItem(MAX_TIMER)) || 3;
    /** Ticks left before running cleanups */
    ticksLeft: number = -1;
    /** Reference to the active timer */
    timer: any = null;
    /** Mapping of dirtyables components */
    dirtyables: Map<any, Function> = new Map();
    /** Callbacks executing at each tick */
    staticTicks: Array<CallableFunction> = [];

    /**
     * Sets the time limit before running cleanups
     * @param v
     */
    setMaximum(v: number): void {
        this.maximum = v;
        localStorage.setItem(MAX_TIMER, v.toString())
    }

    /**
     * Callback for each tick
     * @private
     */
    private _onTick(): void {

        if (this.ticksLeft === 0) {
            this.stop();
        } else {
            this.ticksLeft--
        }
        this.staticTicks.forEach(item => item())


    }

    /**
     * Start the timer
     * @param onElapse cleanup function at time elapse
     * @param onTick Function
     */

    start(onElapse: NamedFunction, onTick?: Function): void {

        this.ticksLeft = this.maximum;
        this.dirtyables.set(onElapse.subject, onElapse.fun);

        if (!this.timer) {

            this.timer = setInterval(
                onTick ? () => {
                    onTick();
                    this._onTick();
                } : () => {
                    this._onTick()
                },
                1000
            )

        }
        this.staticTicks.forEach(item => item())
    }

    /**
     * Make all the cleanup functions identified by values in ids with no-op
     *
     * This is useful when e.g. a component that initiated the timer won't render because
     * it is not anymore in the DOM.
     *
     * @param ids
     */
    silence(...ids: any[]): void {
        ids.forEach((i) => {
            this.dirtyables.set(i, () => {
            })
        });
    }

    isRunning(id: any): boolean {
        return this.dirtyables.has(id)
    }

    /**
     * Stop the timer, run all the callbacks, clear the dirtyables.
     */
    stop(): void {
        clearInterval(this.timer);
        this.timer = null;
        this.ticksLeft = -1;
        this.dirtyables.forEach(cleanup => cleanup());
        this.staticTicks.forEach(item => item());
        this.dirtyables.clear();
    }

}
