import {WorkingCopy} from "./WorkingCopy";
import {WObject} from "./WObject";
import {mapIter} from "../../funcs/iterators"
export type Key = string | symbol | number;

/**
 * WorkingCopy for a list of object references that are uniquely identified (within the list).
 *
 * Clients use
 *      add(item:WorkingCopy<VALUE>) :Key
 * to pass explicitly a change in a list member.
 *
 * It is up to the client to pass 'sub' so to respect the following
 * **consistency invariant:**
 *
 * if
 *       [k(x.extValue), x]  in this._staged
 * then
 *       [k(x), x.extValue]  in this._extValue and
 *              x.extValue   in this.extValue
 *
 */
export class WList<VALUE extends object> implements
    WorkingCopy<VALUE[]>{

    get staged(): Partial<VALUE>[] {
        // Safe to ignore.
        // @ts-ignore
        return [ ... mapIter(this._staged.values(),(item) => item.staged)]
    }

    get extValue() : VALUE[] {
        return [ ... this._extValue.values()]
    }

    wc: VALUE[]

    /**
     * Unique id function
     * @private
     */
    private _key:( item:VALUE)=>Key
    /**
     * Array of keys indexed by the original ordering of extValue.
     * @private
     */
    private _inverseKey: Key[]
    /**
     * Map the unique key to the working copy for the object it identifies.
     * @private
     */
    private _staged: Map<Key, WorkingCopy<VALUE>>
    /**
     * Map the unique key to the object it identifies.
     * @private
     */
    private _extValue: Map<Key, VALUE>


    /**
     * Constructor
     *
     * @param extValue external value
     * @param key Function mapping each item in extValue to a unique key within extValue
     */
    constructor(extValue: VALUE[], key:(item:VALUE)=>Key) {
        this._key = key

        this._staged = new Map()
        this._extValue = new Map(extValue.map((item) => [key(item), item]));
        this._inverseKey = [...this._extValue.keys()]
        const enclosingThis = this;

        this.wc = new Proxy<VALUE[]>(this.extValue, {
            // This proxies a *list*
            get(target: VALUE[], p: Key, receiver: any): any {
                // Safe to ignore. Since this object proxies a list then
                // no problem should arise.
                // @ts-ignore
                let key = enclosingThis._inverseKey[p]

                let original = Reflect.get(target, p)
                if (key !== undefined && enclosingThis._staged.has(key)){
                    return enclosingThis._staged.get(key)!.wc
                } else {
                    return original
                }


            }

        })
    }

    /**
     * Add a new working copy to the stage.
     *
     * CAVEAT EMPTOR:
     * It is up to the client to make the WorkingCopy consistent with this.extValue
     * and the WorkingCopy abstraction.
     * That is, no check is made to ensure that invariants are respected.
     * See class documentation above for invariant definition.
     *
     * @param item A working copy
     */
    add(item:WorkingCopy<VALUE>) :Key{
        let key = this._key(item.extValue)
        this._extValue.set(key, item.extValue)
        this.set(key, item)
        return key
    }

    /**
     * Add an item to this._extValue.
     *
     * It might be used in case a new item is added to the list that was not present
     * when calling the constructor.
     *
     * @param item
     */
    addItem(item:VALUE) {
        let key = this._key(item)
        this._extValue.set(key, item)
    }

    private set(k: Key, v: WorkingCopy<VALUE>) :boolean{
        this._staged.set(k, v)
        return true
    }

    get(k: Key): VALUE {
        let got = this._staged.get(k)?.wc ?? this._extValue.get(k)!
        return got
    }

    isDirty(): boolean {
        return this._staged.size > 0
    }

    unstage(): boolean {
        this._staged.clear()
        return true
    }
}